import { Metric } from 'user/api/dashboardsWidgets';
import { Stream } from 'user/api/streams';
import { AccumulationTypes, convertAccumulationType } from 'shared/models/AccumulationType';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import JsonpathInput from 'user/components/molecules/JsonpathInput';
import SelectBox from 'shared/components/atoms/SelectBox';
import React, { useState } from 'react';

import { graph_types, MetricGraphType, toMetricGraphType } from 'shared/models/MetricGraphType';
import styles from 'shared/styles/styles';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { MetricStatisticMethod } from 'shared/models/MetricStatisticMethod';
import { Thead, Th, Tbody, Tr, Td, WholeArea, Table, TableArea, FooterArea } from './StyledComponent';

interface GraphMetricsTableProps {
  values: Metric[];
  streams: Stream[];
  gp_stream_id: string;
  graph_type: MetricGraphType;
  isGraphType: boolean;
  onCreate: (p: boolean) => void;
  onChange: (value: Metric[]) => void;
  isLiveText: boolean;
}
const GraphMetricsTable: React.FC<GraphMetricsTableProps> = (props) => {
  const [stream_display, setStreamDisplay] = useState<boolean>(false);
  const handleChange = (value: string, index: number, key: string) => {
    const new_metrics = props.values.slice();
    if (key === 'name') {
      new_metrics[index].name = value;
    }
    if (key === 'json_path') {
      new_metrics[index].json_path = value;
    }
    if (key === 'stream_id') {
      new_metrics[index].stream_id = value;
    }
    if (key === 'accumulation') {
      new_metrics[index].accumulation = convertAccumulationType(value);
    }
    if (key === 'stack_id') {
      new_metrics[index].stack_id = value;
    }
    if (key === 'graph_type') {
      new_metrics[index].graph_type = toMetricGraphType(value);
    }
    props.onChange(new_metrics);
  };
  const handleChangeStatisticMethod = (value: MetricStatisticMethod, index: number) => {
    const new_metrics = props.values.slice();
    new_metrics[index].statistic_method = value;
    props.onChange(new_metrics);
  };
  const handleAddRow = () => {
    const new_metrics = props.values.slice();
    if (new_metrics.length >= 100) {
      AlertDialog.show('メトリクスは最大で100行までです。');
      return;
    }
    new_metrics.push({
      name: '',
      stream_id: props.gp_stream_id? props.gp_stream_id : '',
      json_path: '',
      statistic_method: '',
      accumulation: 'NONE',
      stack_id: '1',
      graph_type: props.graph_type,
    });
    props.onChange(new_metrics);
  };
  const handleDeleteRow = () => {
    const new_metrics = props.values.slice();
    if (new_metrics.length <= 1) {
      AlertDialog.show('メトリクスは少なくとも1つ以上の入力が必要です');
      return;
    }
    new_metrics.pop();
    props.onChange(new_metrics);
  };
  const handleCreateClick = () => {
    //設定からやり直すをクリックした場合、グラフ情報設定を活性化し、グラフ情報を非活性にする
    props.onCreate(false);
  };
  const handleStreamDisplayCheck = () => {
    setStreamDisplay(stream_display ? false : true);
  };
  const statistic_methods = [
    { name: '最大値', value: 'Maximum' },
    { name: '最小値', value: 'Minimum' },
    { name: '合計値', value: 'Sum' },
    { name: '平均値', value: 'Average' },
    { name: '値の数', value: 'Count' },
  ];
  return (
    <WholeArea>
      {/* テーブル */}
      <TableArea>
        <Table>
          <Thead>
            <tr>
              {/* テーブルヘッダー */}
              <Th style={{ width: 215 }}>表示名</Th>
              <Th style={{ width: 215 }}>JSONパス</Th>
              {stream_display && <Th style={{ width: 445 }}>対象データ</Th>}
              <Th style={{ width: 100 }}>集計方法</Th>
              <Th style={{ width: 100 }}>累積期間</Th>
              <Th style={{ width: 70 }}>スタック</Th>
              <Th style={{ width: 120 }}>グラフタイプ</Th>
            </tr>
          </Thead>

          {/* テーブルボディー */}
          <Tbody>
            {/* ボディーデータをソートした後に、一ページ分のデータに切り分ける */}
            {props.values.map((body, index1) => {
              return (
                <Tr key={index1}>
                  <Td key={`name_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                    <InputBox value={body.name} onChange={(e) => handleChange(e.currentTarget.value, index1, 'name')} />
                  </Td>
                  <Td key={`json_path_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                    <JsonpathInput
                      value={body.json_path}
                      onJsonpathChanged={(item: string) => handleChange(item, index1, 'json_path')}
                      placeholder='$から始めてください'
                      stream_id={body.stream_id}
                    />
                  </Td>
                  {stream_display ? (
                    <Td key={`stream_id_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                      <SelectBox
                        value={body.stream_id || ''}
                        datas={props.streams.map((s) => {
                          return { name: s.stream_name, value: s.stream_id };
                        })}
                        onChange={(e) => handleChange(e.currentTarget.value, index1, 'stream_id')}
                        long
                      />
                    </Td>
                  ) : null}
                  <Td
                    key={`statistic_method_${index1}`}
                    last_row={index1 + 1 === props.values.length ? true : false}
                    last_child
                  >
                    <SelectBox
                      value={body.statistic_method}
                      datas={statistic_methods}
                      onChange={(e) =>
                        handleChangeStatisticMethod(e.currentTarget.value as MetricStatisticMethod, index1)
                      }
                      long
                    />
                  </Td>
                  {props.isGraphType && (
                    <>
                      <Td key={`accumulation_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                        <SelectBox
                          value={body.accumulation || ''}
                          datas={AccumulationTypes.map((d) => {
                            return {
                              value: d,
                              name: d,
                            };
                          })}
                          onChange={(e) => handleChange(e.currentTarget.value, index1, 'accumulation')}
                          long
                        />
                      </Td>
                      <Td key={`stack_id_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                        <SelectBox
                          value={body.stack_id || ''}
                          default_text=''
                          default_text_value=''
                          datas={['1', '2', '3', '4', '5', '6', '7', '8', '9'].map((d) => {
                            return {
                              value: d,
                              name: d,
                            };
                          })}
                          onChange={(e) => handleChange(e.currentTarget.value, index1, 'stack_id')}
                          long
                        />
                      </Td>
                      <Td key={`graph_type_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                        <SelectBox
                          value={body.graph_type || ''}
                          datas={graph_types.map((d) => {
                            return {
                              value: d,
                              name: d,
                            };
                          })}
                          onChange={(e) => handleChange(e.currentTarget.value, index1, 'graph_type')}
                          long
                        />
                      </Td>
                    </>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableArea>
      <CheckBoxWithText text='対象データを表示する' checked={stream_display} onClick={handleStreamDisplayCheck} />

      {/* フッター */}
      <FooterArea>
        <RoundedButton
          text='設定からやり直す'
          onClick={handleCreateClick}
          style={{
            width: styles.small_button_width,
            height: styles.super_small_button_height,
            marginRight: styles.interval_narrow_margin,
          }}
          is_white
        />
        <RoundedButton
          text='削除'
          onClick={handleDeleteRow}
          style={{
            width: styles.super_small_button_width,
            height: styles.super_small_button_height,
            marginRight: styles.interval_narrow_margin,
          }}
          is_white
        />
        <RoundedButton
          text='追加'
          onClick={handleAddRow}
          style={{
            width: styles.super_small_button_width,
            height: styles.super_small_button_height,
          }}
        />
      </FooterArea>
    </WholeArea>
  );
};

export default GraphMetricsTable;
