import React from 'react';
import { DashboardBusOdMetric, DashboardWidget } from 'user/api/dashboardsWidgets';
import FixedDateScaleSettingDialog from 'user/dialogs/FixedDateScaleSettingDialog';
import { getDateScaleLabel } from 'shared/utils/get';
import { TopButtonArea } from 'shared/components/molecules/ContentsArea';
import { WidgetState } from 'user/pages/Dashboards/DetailPage/UsualDashboardWidgets/DashboardWidgetCard';
import useDateRangeWidgetBase2 from './useDateRangeWidgetBase2';
import { GraphArea } from './GraphArea';
import DownloadIcon from 'shared/components/atoms/Icons/DownloadIcon';

export type WidgetBase2Type = 'BUSOD1' | 'BUSOD2' | 'BUSOD3';
interface DateRangeWidgetBase2Props {
  widget: DashboardWidget;
  state: WidgetState;
  // グラフに描画するデータ。datapointがX軸ラベル、keyにデータ
  data?: DashboardBusOdMetric[];
  route_long_name?: string;
  widget_type: WidgetBase2Type;
  onInitMetrics: () => void;
  onStateChanged: (state: WidgetState) => void;
  onMetricsChanged: (metrics: DashboardBusOdMetric[]) => void;
  children?: React.ReactNode;
}

/**
 * グラフ描画ウィジェットです。
 *  - バスOD用の新しいメトリクス取得APIをコールします。
 */
export default function DateRangeWidgetBase2({
  children,
  widget,
  state,
  data,
  route_long_name,
  widget_type,
  onMetricsChanged,
  onInitMetrics,
  onStateChanged,
}: DateRangeWidgetBase2Props) {
  const hide_scale = true;
  // 必要なhooksや関数を取得
  const { date_dialog_open, csv_data_download_url, onDateDialogOpen, handleDateUpdated } = useDateRangeWidgetBase2({
    state,
    widget,
    data,
    route_long_name,
    widget_type,
    onMetricsChanged,
    onInitMetrics,
    onStateChanged,
  });
  const { start_date, end_date, start_time, end_time, fixed } = state;
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <TopButtonArea style={{ justifyContent: 'space-between' }}>
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onDateDialogOpen}>
          {getDateScaleLabel({
            start_date,
            end_date,
            start_time,
            end_time,
            scale: !hide_scale ? state.scale : undefined,
            fixed,
          })}
        </span>
        <DownloadIcon
          url={csv_data_download_url}
          download_name={
            widget.dashboard_widget_name +
            getDateScaleLabel({
              start_date: state.start_date,
              end_date: state.end_date,
              start_time: state.start_time,
              end_time: state.end_time,
              scale: !hide_scale ? state.scale : undefined,
              fixed,
            })
          }
        />
      </TopButtonArea>
      <GraphArea>{children}</GraphArea>
      {date_dialog_open && (
        <FixedDateScaleSettingDialog
          onClose={handleDateUpdated}
          widget={widget}
          hide_scale={hide_scale}
          defaults={{
            start_date,
            end_date,
            start_time,
            end_time,
          }}
        />
      )}
    </div>
  );
}
