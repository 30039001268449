// -- basic library --
import React, { useCallback, useEffect, useState } from 'react';

// -- external components --
import { RouteComponentProps } from 'react-router-dom';
import RoundedAnchorButton from 'shared/components/atoms/RoundedAnchorButton';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import { Content } from 'shared/components/molecules/ContentsArea';
import { ADOrder } from 'shared/components/molecules/SelectBoxOrder';
import BaseTable from 'shared/components/molecules/Table/BaseTable';

// -- http connection library --
import { TableHeaderType, TableBodyMultipleValueType } from 'shared/components/molecules/Table/type';

// -- external types --

// -- external functions --
import { table_cell_button_style } from 'shared/styles/styles';
import { isNotSelected } from 'shared/utils/is';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import { toFormatBytes } from 'shared/utils/converter';
import { Stream } from 'user/api/streams';
import {
  streamsIdDataNumberDownloadGetAPI,
  streamsIdDataGetAPI,
  getStreamsIdDataNumberDownloadGetAPIUrl,
  StreamData,
} from 'user/api/streamsData';
import { StreamDataNumberType } from 'shared/models/StreamDataNumberType';
import { loadWrapperFunc } from 'user/utils/loadWrapperFunc';

// -- type declaration --

type Params = RouteComponentProps<{ stream_id: string }> & { stream: Stream };

export type TableStreamDataDownloadType = {
  id: string;
  stream_data_number: string;
  stream_data_name: string;
  size: string;
  create_date: string;
  download: TableBodyMultipleValueType<string>;
};
// 一度に取得するStreamDataの個数
const GET_STREAM_DATA_LIMIT = 100;

// テーブルの列の情報まとめたデータを返却する
const getHeadCells = (data_number_type: StreamDataNumberType): TableHeaderType[] => {
  const is_timestamp = data_number_type === 'TIMESTAMP';
  return [
    {
      id: 'stream_data_number',
      label: 'ID',
      width: '15%',
      search_props: {
        type: is_timestamp ? 'datetime' : 'name',
      },
    },
    {
      id: 'stream_data_name',
      label: 'ストリームデータ名',
      width: 'auto',
      search_props: {
        type: 'name',
        default_display: true,
      },
    },
    {
      id: 'size',
      label: 'データサイズ',
      width: '15%',
      search_props: {
        type: 'name',
      },
    },
    {
      id: 'create_date',
      label: 'データ受信日時',
      width: '10%',
      search_props: {
        type: 'datetime',
      },
    },
    {
      id: 'download',
      label: '',
      useTableButtonStyles: true,
    },
  ];
};

/**
 * download列の値を返却する
 */
const getButtonElement = (stream_id: string, item: StreamData): TableBodyMultipleValueType<string> => {
  const button_text = item.is_ready_for_process ? 'ダウンロード' : '処理中...';
  let button = (
    <RoundedButton
      text={button_text}
      disabled={true}
      style={{
        ...table_cell_button_style,
        fontSize: '0.8em',
      }}
      stop_propagation
    />
  );
  if (item.is_ready_for_process) {
    button = (
      <RoundedAnchorButton
        text={button_text}
        href={getStreamsIdDataNumberDownloadGetAPIUrl({
          stream_id: stream_id,
          stream_data_number: item.stream_data_number,
        })}
        style={{
          ...table_cell_button_style,
          fontSize: '0.8em',
        }}
      />
    );
  }
  return { value: button_text, available_value: button_text, displayable_value: button };
};

// -- external functions --

// -- main component --
const StreamDataDownloadPanel: React.FC<Params> = (params) => {
  const table_massege = '＞チェックした項目を全てダウンロードする';

  // -- local states --
  const [table_bodies, setTableBodies] = useState<TableStreamDataDownloadType[] | undefined>(undefined);
  const [selected_bodies, setSelectedBodies] = useState<TableStreamDataDownloadType[]>([]);
  // データの取得順序を変更する
  const [order, setOrder] = useState<ADOrder>('descending');
  const [exclusive_start_key, setExclusiveStartKey] = useState<string | undefined>(undefined);
  const [has_next, setHasNext] = useState<boolean>(false);

  // -- handlers --
  const handleCheckClick = (bodies: TableStreamDataDownloadType[]) => {
    setSelectedBodies(bodies);
  };

  // StreamData[] -> TableStreamDataDownloadType[]にしてデータを変換する
  const convertStreamDatasToTableDatas = useCallback(
    (items: StreamData[], stream_id: string) => {
      const return_table_datas: TableStreamDataDownloadType[] = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const return_table_data: TableStreamDataDownloadType = {
          id: String(item.stream_data_number),
          stream_data_number:
            params.stream.data_number_type === 'TIMESTAMP'
              ? String(dateToYMDHMS(item.stream_data_number * 1000))
              : String(item.stream_data_number),
          stream_data_name: item.stream_data_name,
          size: toFormatBytes(item.data_size, 2),
          create_date: String(dateToYMDHMS(item.created_at)),
          download: getButtonElement(stream_id, item),
        };
        return_table_datas.push(return_table_data);
      }
      return return_table_datas;
    },
    [params.stream.data_number_type],
  );

  // データを取得する関数
  const getDatas = useCallback(
    async (stream_id: string, order?: ADOrder) => {
      const res = await streamsIdDataGetAPI({
        stream_id: stream_id,
        scan_index_forward: order === 'descending' ? 'False' : 'True',
        limit: GET_STREAM_DATA_LIMIT,
      });
      if (res.status !== 200) return;
      const return_table_datas: TableStreamDataDownloadType[] = convertStreamDatasToTableDatas(
        res.data.items,
        stream_id,
      );
      setTableBodies(return_table_datas);
      setExclusiveStartKey(
        res.data.last_evaluated_stream_data_number ? res.data.last_evaluated_stream_data_number + '' : undefined,
      );
      setHasNext(res.data.has_next);
    },
    [convertStreamDatasToTableDatas],
  );

  // データを追加する関数
  const addDatas = async (stream_id: string, order?: ADOrder) => {
    // 次のデータが存在しないのなら、この関数を呼ぶ必要がない
    if (!has_next || !exclusive_start_key) {
      return;
    }

    const res = await streamsIdDataGetAPI({
      stream_id: stream_id,
      scan_index_forward: order === 'descending' ? 'False' : 'True',
      limit: GET_STREAM_DATA_LIMIT,
      exclusive_start_key: exclusive_start_key,
    });
    if (res.status !== 200) return;

    const return_table_datas: TableStreamDataDownloadType[] = convertStreamDatasToTableDatas(res.data.items, stream_id);

    let ntbs = [];
    if (table_bodies === undefined) {
      ntbs = return_table_datas;
    } else {
      ntbs = [...table_bodies, ...return_table_datas];
    }
    setTableBodies(ntbs);
    setExclusiveStartKey(
      res.data.last_evaluated_stream_data_number ? res.data.last_evaluated_stream_data_number + '' : undefined,
    );
    setHasNext(res.data.has_next);
  };

  const handleMultiDownoadClick = useCallback(
    async (stream_id: string) => {
      // ダウンロード関数定義
      const multipleDownload = async () => {
        await Promise.all(
          selected_bodies.map((sd) =>
            streamsIdDataNumberDownloadGetAPI({
              stream_id: stream_id,
              stream_data_number: sd.id,
            }),
          ),
        );
        await getDatas(stream_id, order);
        setSelectedBodies([]);
      };
      // 実行
      await loadWrapperFunc(multipleDownload);
    },

    [order, selected_bodies, getDatas],
  );

  // -- onload function --
  useEffect(() => {
    (async function () {
      // プロセス情報の取得
      await getDatas(params.match.params.stream_id, order);
    })();
  }, [order, getDatas]); /* eslint-disable-line */

  // -- render part --
  return (
    <Content>
      {table_bodies !== undefined ? (
        <BaseTable
          handleAddDatas={() => addDatas(params.match.params.stream_id, order)}
          has_next={has_next}
          bodies={table_bodies}
          headers={getHeadCells(params.stream.data_number_type)}
          table_name='streamsDownloads'
          selected_bodies={selected_bodies}
          handleCheckClick={handleCheckClick}
          footer_option={{
            text: table_massege,
            handleClick: async () => await handleMultiDownoadClick(params.match.params.stream_id),
            disabled: isNotSelected(selected_bodies.length),
          }}
          orderProps={{
            order: order,
            setOrder: setOrder,
          }}
        />
      ) : (
        <Spinner />
      )}
    </Content>
  );
};

// -- styled components --

// -- finally export part --
export default StreamDataDownloadPanel;
