import { Metric } from 'user/api/dashboardsWidgets';
import { isMetricGraphType } from 'shared/models/MetricGraphType';
import { isJsonPath } from 'shared/utils/is';
import { isNotOnlySpace } from 'shared/utils/is';

const checkMetrics = (widget_type: string, metrics: Metric[]) => {
  if (widget_type === 'VIDEOPLAYER') return true;
  // データの長さにおけるヴァリデーション
  if (metrics.length < 1 || metrics.length > 200) {
    return false;
  }
  // メトリクスデータの中身のヴァリデーション
  for (let i = 0; i < metrics.length; i++) {
    if (
      !isNotOnlySpace(metrics[i].name) ||
      !isJsonPath(metrics[i].json_path) ||
      !isNotOnlySpace(metrics[i].stream_id) ||
      metrics[i].statistic_method === '' ||
      (!isMetricGraphType(metrics[i].graph_type) && widget_type === 'GRAPH')
    )
      return false;
  }

  return true;
};

export default checkMetrics;
