import AlertDialog from 'shared/components/molecules/AlertDialog';
import { isDateString } from 'shared/models/DateString';
import FeatureDatetime from 'shared/models/FeatureDatetime';
import { isTimeString } from 'shared/models/TimeString';
import { isWidgetScaleType, scaleToStep, widgetScaleToText } from 'shared/models/WidgetScaleType';
import { WidgetSetting } from 'user/api/dashboardsWidgets';

export const getDateLabel = (start?: FeatureDatetime, end?: FeatureDatetime) => {
  if (!start || !end) return '日付を選択してください';
  const v1 = start.getLabel();
  const v2 = end.getLabel();
  if (v1 === v2) {
    return v1;
  } else if (v1 === 'カスタム' || v2 === 'カスタム') {
    return 'カスタム';
  } else {
    return v1 + 'から' + v2;
  }
};
/**
 *
 * @param args
 * @returns データの期間表示
 * 例：2023-01-12 ~ 2023-01-12 [00:00 ~ 23:59](時)
 */
export const getDateScaleLabel = (args?: WidgetSetting & { fixed?: boolean }) => {
  if (args === undefined) return '日付を選択してください';
  const { start_time, end_time, start_date, end_date, scale, fixed = false } = args;
  let date_label = '';
  let time_label = '';
  if (isDateString(start_date) && isDateString(end_date)) {
    date_label = `${start_date} ~ ${end_date}`;
  }
  if (isTimeString(start_time) && isTimeString(end_time)) {
    time_label = `${start_time} ~ ${end_time}`;
  }
  if (date_label.length === 0 && time_label.length === 0) return '日付を選択してください';
  let label = '';
  if (date_label.length > 0 && time_label.length > 0) {
    label = `${date_label} [${time_label}]`;
  } else {
    // 片方のみ存在の場合はどちらかが空文字列なので単純な結合でOK
    label = `${date_label}${time_label}`;
  }
  // scaleが存在すれば、情報を追加
  if (isWidgetScaleType(scale)) {
    label += `(${widgetScaleToText(scale)})`;
  }
  if (fixed) {
    label = `(固定)${label}`;
  }
  return label;
};

/**
 * ファイルをテキスト型に変更し、それをJSON.parseする関数
 * **/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getJsonReadedAsTextFromFile = (file: File, callback?: (result: any) => void) => {
  // resultが他のapiのformの代わり
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = {};
  // fileデータを読み込んでarraybufferに変換する処理
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        result = JSON.parse(reader.result);
      }
      resolve(result);
    };
    try {
      reader.readAsText(file);
    } catch {
      console.log('fileの読み込みに失敗');
    }
  })
    .then(() => {
      if (callback) {
        callback(result);
      }
    })
    .catch((e) => {
      AlertDialog.show(e);
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDownloadUrlAsJson = (data: any) => {
  if (!data) {
    return undefined;
  }
  const blob = new Blob([JSON.stringify(data, null, ' ')], { type: 'application/json' });

  const url = URL.createObjectURL(blob);
  return url;
};

/**
 * オブジェクトの message フィールドを安全に取得します。
 * そのようなフィールドが無い場合は、空文字列を返します。
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMessageField(o: any) {
  if (o instanceof Object && o.message) return o.message;
  return '';
}

export const getProgress = (progress: number, done: boolean) => {
  let return_progress_text = '0%';
  if (progress <= 0) {
    return_progress_text = '0%';
  } else if (progress > 0 && progress < 100) {
    return_progress_text = `${progress}%`;
  } else if (progress === 100 && done) {
    return_progress_text = '100%(Done)';
  } else if (progress === 100) {
    return_progress_text = '100%';
  } else {
    return_progress_text = 'None';
  }
  return return_progress_text;
};

/** 現在時刻から指定のミリ秒後の日付を秒(文字列)で返却する関数**/
export const getSpecifiedMinute = (msec: number): string => {
  // 現在時刻
  const now = new Date();
  // 現在時刻+指定ミリ秒
  const specified_date_msec = now.getTime() + msec;
  // ミリ秒を分にして切り捨て
  const specified_date = ~~(specified_date_msec / (1000 * 60));

  // 分を秒に直して、文字列にして返却
  return String(specified_date * 60);
};

import { WidgetScaleType } from 'shared/models/WidgetScaleType';
import { scaleToWidgetPeriod } from 'shared/models/WidgetScaleType';
import { dayOfWeekStr } from '../constants';

export interface WidgetRequestInfo {
  start: number;
  end: number;
  period: 'min' | 'hour' | 'day';
}

export const getWidgetRequestInfos = (start: number, end: number, scale: WidgetScaleType) => {
  const request_infos: WidgetRequestInfo[] = [];
  // step
  const step = scaleToStep(scale);
  const period = scaleToWidgetPeriod(scale);
  for (let t = start; t < end; t += step) {
    request_infos.push({
      start: t,
      end: Math.min(t + step, end),
      period: period || 'day',
    });
  }
  return request_infos;
};

export const getWeekDates = (endDate: Date, roopNumber = 7) => {
  const returnDateList: string[] = [];
  const d = new Date(endDate);
  const month = d.getMonth() + 1;
  const date = d.getDate();
  returnDateList.push(`${month}/${date}`);
  // 基本的には1週間分(7つ)のデータを返すが、変更できる
  for (let i = 0; i < roopNumber - 1; i++) {
    d.setDate(d.getDate() - 1);
    const month = d.getMonth() + 1;
    const date = d.getDate();
    returnDateList.push(`${month}/${date}`);
  }

  returnDateList.reverse();
  return returnDateList;
};

/**
 * 高さとレシオから横幅を出力する
 * @param {height}: number
 * @param {ratio}: number - 縦横比(横/縦)
 * @return {width}: number
 * **/
export const getWidth = (p: { height: number; ratio: number }): number => {
  return p.height * p.ratio;
};

/**
 * 横幅とレシオから高さを出力する
 * @param {width}: number
 * @param {ratio}: number - 縦横比(横/縦)
 * @return {height}: number
 * **/
export const getHeight = (p: { width: number; ratio: number }): number => {
  return p.width / p.ratio;
};

export const getDayOfWeek = (date: string, dw = dayOfWeekStr) => {
  const monthAndDay = date.split('/');
  const d = new Date();
  d.setMonth(Number(monthAndDay[0]) - 1);
  d.setDate(Number(monthAndDay[1]));
  return dw[d.getDay()];
};

export const getDayOfWeelColor = (date: string) => {
  const monthAndDay = date.split('/');
  const d = new Date();
  d.setMonth(Number(monthAndDay[0]) - 1);
  d.setDate(Number(monthAndDay[1]));
  const dayOfWeek = d.getDay();
  if (dayOfWeek === 0) {
    return 'red';
  } else if (dayOfWeek === 6) {
    return 'blue';
  } else {
    return 'black';
  }
};

export const getAddedDay = (date: Date, num: number) => {
  const d = new Date(date);
  d.setDate(d.getDate() + num);
  return d;
};

export const getDownloadUrlAsCsv = (data: (string | number)[][]) => {
  // 2次元配列を取得し、取得した配列をcsvファイルとして出力するためのURLを返す。
  if (!(data instanceof Array)) {
    return undefined;
  }
  // 取得した2次元配列をcsv形式に成型
  const csv_data = data.map((csv) => csv.join(',')).join('\r\n');
  // BOMの設定
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, csv_data], { type: 'text/csv' });

  const url = URL.createObjectURL(blob);
  return url;
};

// -- type declaration --
type Order = 'asc' | 'desc';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const descendingComparator = (a: any, b: any, order_by: keyof any) => {
  if (b[order_by] < a[order_by]) {
    return -1;
  }
  if (b[order_by] > a[order_by]) {
    return 1;
  }
  return 0;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// 開発環境のサブドメイン名
export const getSubDomain = () => {
  const env_name = import.meta.env.VITE_ENV_NAME;
  // 本番環境の時はドメインを本番に合わせる
  if (env_name === 'prod') {
    return '.platform.i-dea.ai';
  } else if (env_name) {
    return `.${import.meta.env.VITE_ENV_NAME}.dev-platform.i-dea.ai`;
  } else {
    return '.dev-platform.i-dea.ai';
  }
};

export const average = (x: number, y: number) => {
  return (x + y) / 2;
};
