import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import history from 'shared/browserHistory';
import InputBox from 'shared/components/atoms/InputBox';
import { PfDialog } from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { LiveTextSettingPaneValues } from 'shared/components/molecules/LiveTextPropertiesPane';
import PageSwitchingTabs from 'shared/components/molecules/PageSwitchingTabs';
import { WidgetGraphSettingPanel } from 'shared/components/molecules/WidgetSettingPanels/WidgetGraphSettingPanel';
import styles from 'shared/styles/styles';
import { widget_types } from 'shared/utils/constants';
import { widgetPostValidator } from 'user/utils/validators/widgets';
import { Dashboard } from 'user/api/dashboards';
import { dashboardsIdWidgetsPostAPI, Metric } from 'user/api/dashboardsWidgets';
import { CachedStreams, Stream } from 'user/api/streams';
import DashboardWidgetDataInformation from 'user/pages/Dashboards/DetailPage/DashboardWidgetDataInformation/DashboardWidgetDataInformation';
import { GraphSettingPanelValues } from '../components/GraphSettingPanel';
import { MetricGraphType } from 'shared/models/MetricGraphType';

type CreateDashboardWidgetDialogProps = RouteComponentProps<{
  dashboard_id: string;
  panel_id?: string;
}> & {
  isOpen: boolean;
  onClose: (is_canceled: boolean) => void;
  dashboard: Dashboard;
  metric_streams: CachedStreams;
  player_streams: CachedStreams;
};
interface CreateDashboardWidgetDialogState {
  dashboard_widget_name: string;
  widget_type: string;
  metrics: Metric[];
  metric_streams?: Stream[];
  player_streams?: Stream[];
  html: string;
  html_script: string;
  create_graph: boolean;
  graph_type: MetricGraphType;
  jsonpath_group_id?: string;
  player_stream_id: string;
  od_stream_id: string;
  od_age: boolean;
  od_gender: boolean;
  hm_stream_id: string;
  gp_stream_id: string;
  y_min?: string;
  y_max?: string;
}
/**
 * ダッシュボードウィジェット作成ダイアログです。
 */
export default class CreateDashboardWidgetDialog extends React.PureComponent<
  CreateDashboardWidgetDialogProps,
  CreateDashboardWidgetDialogState
> {
  constructor(props: CreateDashboardWidgetDialogProps) {
    super(props);
    this.state = {
      dashboard_widget_name: '',
      widget_type: '',
      metrics: [
        {
          name: '',
          stream_id: '',
          json_path: '',
          statistic_method: '',
        },
      ],
      html: '',
      html_script: '',
      create_graph: false,
      graph_type: '',
      jsonpath_group_id: '',
      player_stream_id: '',
      od_stream_id: '',
      od_age: false,
      od_gender: false,
      hm_stream_id: '',
      gp_stream_id: '',
      y_min: '',
      y_max: '',
    };
  }
  componentDidMount() {
    // メトリクス用のストリーム一覧の取得
    this.props.metric_streams.get().then((data) => {
      this.setState({ metric_streams: data });
    });

    // widget_typeがVIDEOPLAYER用のストリーム一覧の取得
    this.props.player_streams.get().then((data) => {
      // ただdata_number_typeがTIMESTAMPのみを受け付けるのでフィルタリングしてstateに加える
      const newPlayerStreams: Stream[] = data.filter((d) => {
        return d.data_number_type === 'TIMESTAMP';
      });
      this.setState({ player_streams: newPlayerStreams });
    });
  }
  private handleFinishClick = () => {
    const {
      dashboard_widget_name,
      widget_type,
      y_min,
      y_max,
      html,
      html_script,
      graph_type,
      jsonpath_group_id,
      player_stream_id,
      od_stream_id,
      od_age,
      od_gender,
      hm_stream_id,
      gp_stream_id,
      metrics,
    } = this.state;
    try {
      // validation後のrequestデータ取得
      const request = widgetPostValidator({
        dashboard_id: this.props.dashboard.dashboard_id,
        dashboard_widget_name,
        widget_type,
        widget_setting: {
          y_min,
          y_max,
        },
        html,
        html_script,
        graph_type,
        jsonpath_group_id,
        player_stream_id,
        od_stream_id,
        od_age,
        od_gender,
        hm_stream_id,
        gp_stream_id,
        metrics,
      });
      dashboardsIdWidgetsPostAPI(request).then((res) => {
        if (res.status === 200) {
          AlertDialog.show('ウィジェットの作成に成功しました');
          this.props.onClose(false);
        }
      });
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
  };
  private handleLiveTextSettingsChange = (v: LiveTextSettingPaneValues) => {
    this.setState({ html: v.html || '', html_script: v.htmlScript || '' });
  };
  private handleGraphSettingsChange = (v: GraphSettingPanelValues) => {
    const category: string | undefined = this.getCategory(this.state.gp_stream_id);
    const new_category: string | undefined = this.getCategory(v.gp_stream_id);
    if (category != new_category) {
      v.jsonpath_group_id = '';
    }
    this.setState({
      jsonpath_group_id: v.jsonpath_group_id || '',
      gp_stream_id: v.gp_stream_id || '',
      graph_type: v.graph_type || '',
    });
  };
  private getCategory = (stream_id: string | undefined) => {
    let category = '';
    if (this.state.metric_streams && stream_id) {
      for (const stream of this.state.metric_streams) {
        if (stream.stream_id == stream_id) {
          category = stream.category;
        }
      }
    }
    return category;
  };

  private handleWidgetTypeChange = (value: string) => {
    if (value !== 'GRAPH') {
      // tabによるurlを初期化する
      history.push(`/dashboards/${this.props.match.params.dashboard_id}`);
    }
    this.setState({ widget_type: value });
  };

  private handleTabsClick = (tab_id: string, dashboard_id: string) => {
    history.push(`/dashboards/${dashboard_id}/${tab_id}`);
  };

  private get getDisplayData() {
    const displays = [
      {
        id: '',
        text: 'データ情報',
      },
    ];
    if (this.state.widget_type === 'GRAPH') {
      displays.push({
        id: 'graph-setting',
        text: 'グラフ設定',
      });
    }
    return displays;
  }

  render() {
    return (
      <PfDialog isOpen={this.props.isOpen} title='ウィジェット作成' onClose={() => this.props.onClose(true)}>
        <InputComponent text='ウィジェット名' required>
          <InputBox
            placeholder='入力してください'
            value={this.state.dashboard_widget_name}
            onChange={(e) => this.setState({ dashboard_widget_name: e.currentTarget.value })}
          />
        </InputComponent>
        <InputComponent text='ウィジェットタイプ' required>
          <SelectBox
            onChange={(e) => this.handleWidgetTypeChange(e.currentTarget.value)}
            value={this.state.widget_type}
            datas={widget_types.map((widget_type) => {
              return { name: widget_type.name, value: widget_type.value };
            })}
            long
          />
        </InputComponent>
        {!this.state.metric_streams || !this.state.player_streams ? (
          <Spinner />
        ) : (
          <>
            <React.Fragment>
              <PageSwitchingTabs
                display_datas={this.getDisplayData}
                handleClick={(id: string) => this.handleTabsClick(id, this.props.match.params.dashboard_id)}
                selected_id={this.props.match.params.panel_id}
                initial_selected_id=''
              />
              <Switch>
                <Route
                  exact
                  path='/dashboards/:dashboard_id/'
                  render={() => (
                    <DashboardWidgetDataInformation
                      widget_type={this.state.widget_type}
                      player_streams={this.state.player_streams || []}
                      metric_streams={this.state.metric_streams || []}
                      metrics={this.state.metrics}
                      html={this.state.html}
                      html_script={this.state.html_script}
                      create_graph={this.state.create_graph}
                      graph_type={this.state.graph_type}
                      jsonpath_group_id={this.state.jsonpath_group_id}
                      category={this.getCategory(this.state.gp_stream_id)}
                      player_stream_id={this.state.player_stream_id}
                      od_stream_id={this.state.od_stream_id}
                      od_age={this.state.od_age}
                      od_gender={this.state.od_gender}
                      hm_stream_id={this.state.hm_stream_id}
                      gp_stream_id={this.state.gp_stream_id}
                      onPlayerStreamIdChange={(value) => this.setState({ player_stream_id: value })}
                      onOdStreamIdChange={(value) => this.setState({ od_stream_id: value })}
                      onOdAgeChange={(value) => this.setState({ od_age: value })}
                      onOdGenderChange={(value) => this.setState({ od_gender: value })}
                      onHmStreamIdChange={(value) => this.setState({ hm_stream_id: value })}
                      onMetricsChange={(value) => this.setState({ metrics: value })}
                      onGrahpSettingChange={this.handleGraphSettingsChange}
                      onGrahpCreateChange={(value) => this.setState({ create_graph: value })}
                      onLiveTextSettingChange={this.handleLiveTextSettingsChange}
                    />
                  )}
                />
                {this.state.widget_type === 'GRAPH' && (
                  <Route
                    exact
                    path='/dashboards/:dashboard_id/graph-setting'
                    render={(_props) => (
                      <WidgetGraphSettingPanel
                        y_min={this.state.y_min}
                        y_max={this.state.y_max}
                        setYMin={(y_min) => this.setState({ y_min })}
                        setYMax={(y_max) => this.setState({ y_max })}
                      />
                    )}
                  />
                )}
              </Switch>
            </React.Fragment>
            <Footer>
              <RoundedButton
                onClick={() => this.props.onClose(true)}
                text='キャンセル'
                is_white
                style={{
                  marginRight: styles.interval_narrow_margin,
                }}
              />
              <RoundedButton onClick={() => this.handleFinishClick()} text_type='CREATE' />
            </Footer>
          </>
        )}
      </PfDialog>
    );
  }
}

// -- styled components --
