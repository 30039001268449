import { Dashboard, dashboardsIdGetAPI } from 'user/api/dashboards';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import { Content, PageWholeArea } from 'shared/components/molecules/ContentsArea';
import React from 'react';
import CreateDashboardWidgetDialog from 'user/pages/Dashboards/DetailPage/CreateDashboardWidgetDialog';
import DashboardWidgetsView from './DashboardWidgetsView';
import { CachedStreams } from 'user/api/streams';
import { RouteComponentProps } from 'react-router';
import history from 'shared/browserHistory';
import { getDateScaleLabel } from 'shared/utils/get';
import FixedDateScaleSettingDialog, {
  FixedDateScaleSettingDialogCloseProps,
} from 'user/dialogs/FixedDateScaleSettingDialog';
import AutoReloadButton from 'shared/components/molecules/AutoReloadButton';
import { WidgetScaleType } from 'shared/models/WidgetScaleType';
import { DateString } from 'shared/models/DateString';
import { TimeString } from 'shared/models/TimeString';
import { dashboardsIdWidgetsGetAPI } from 'user/api/dashboardsWidgets';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import DashboardTopAreaWrapper from '../components/DashboardTopAreaWrapper';

type DashboardsIdPageProps = RouteComponentProps<{
  dashboard_id: string;
  panel_id?: string;
}> & { is_admin: boolean };

interface DashboardsIdPageState {
  dashboard?: Dashboard;
  // 自動リロード
  enable_auto_reload: boolean;
  auto_reload_interval: number;
  // ダッシュボード詳細用states
  is_dashboard_detail_open: boolean;
  // ダッシュボードウィジェット追加用states
  is_widget_create_open: boolean;
  scale?: WidgetScaleType | null;
  start_date?: DateString | null;
  end_date?: DateString | null;
  start_time?: TimeString | null;
  end_time?: TimeString | null;
  dateDialogState: boolean;
  widgets_number: number;
}
/**
 * ダッシュボード詳細のメインページです。
 * ・ウィジェットの追加
 * ・ウィジェットの表示
 * ・ダッシュボード詳細ダイアログの表示
 */
export default class DashboardsWidgets extends React.PureComponent<DashboardsIdPageProps, DashboardsIdPageState> {
  // 設定ダイアログのためのキャッシュオブジェクト
  private metricStreams: CachedStreams = new CachedStreams({
    data_types: 'METRIC',
    with_in_channel_streams: undefined,
    with_output_streams: 'True',
    disabled_user_type_check: 'True',
  });
  // ウィジェット作成や更新でwidget_type=VIDEOPLAYERの時にplayer_stream_idが必要となる。そのためのオブジェクト
  private playerStreams: CachedStreams = new CachedStreams({
    data_types: 'IMAGE,VIDEO',
    with_in_channel_streams: undefined,
    with_output_streams: 'True',
    disabled_user_type_check: 'True',
  });
  constructor(props: DashboardsIdPageProps) {
    super(props);
    this.state = {
      enable_auto_reload: false,
      auto_reload_interval: 60000,
      is_dashboard_detail_open: false,
      is_widget_create_open: false,
      dateDialogState: false,
      widgets_number: 0,
    };
  }
  componentDidMount() {
    this.reload();
    // tabによるurlを初期化する
    history.push(`/dashboards/${this.props.match.params.dashboard_id}`);
  }
  private reload = () => {
    // ダッシュボードの取得
    dashboardsIdGetAPI({ dashboard_id: this.props.match.params.dashboard_id }).then((res) => {
      if (res.status === 200) {
        this.setState({ dashboard: res.data });
      }
    });
    // ウィジェット数の取得
    dashboardsIdWidgetsGetAPI({
      dashboard_id: this.props.match.params.dashboard_id,
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ widgets_number: res.data.items.length });
      }
    });
  };
  private handleAutoReloadChange = (b: boolean) => {
    this.setState({ enable_auto_reload: b });
  };
  private handleAutoReloadRangeChange = (i: number) => {
    this.setState({
      enable_auto_reload: true,
      auto_reload_interval: i,
    });
  };

  private handleDateClick = () => {
    this.setState({ dateDialogState: true });
  };

  private handleDateDialogClose = (params: FixedDateScaleSettingDialogCloseProps) => {
    const { is_canceled, start_date, end_date, start_time, end_time, scale } = params;
    if (!is_canceled) {
      this.setState({
        start_date,
        end_date,
        start_time,
        end_time,
        scale,
      });
    }
    this.setState({ dateDialogState: false });
  };
  // ウィジェット作成用関数
  private handleDashboardWidgetCreateDialogOpen = () => {
    if (this.state.widgets_number > 11) {
      AlertDialog.show('ウィジェットは12個までしか作成できません');
      return;
    }
    this.setState({ is_widget_create_open: true });
  };
  private handleDashboardWidgetCreateDialogClose = (isCanceled: boolean) => {
    this.setState({ is_widget_create_open: false });
    // tabによるurlを初期化する
    history.push(`/dashboards/${this.props.match.params.dashboard_id}`);
    if (!isCanceled) {
      this.reload();
    }
  };
  // ダッシュボード詳細用関数
  private handleDashboardSetDialogOpen = () => {
    this.setState({ is_dashboard_detail_open: true });
  };
  private handleDashboardSetDialogClose = (isCanceled: boolean) => {
    this.setState({ is_dashboard_detail_open: false });
    if (!isCanceled) {
      this.reload();
    }
  };

  private handleTabsClick = (tab_id: string, dashboard_id: string) => {
    history.push(`/dashboards/${dashboard_id}/${tab_id}`);
  };

  render() {
    const { start_date, end_date, start_time, end_time, scale, dashboard } = this.state;
    const { is_admin } = this.props;

    return (
      <PageWholeArea>
        {dashboard === undefined ? (
          <Spinner />
        ) : (
          <Content>
            <DashboardTopAreaWrapper dashboard={dashboard} is_admin={is_admin}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
                <div
                  style={{ textDecoration: 'underline', cursor: 'pointer', whiteSpace: 'nowrap' }}
                  onClick={() => this.handleDateClick()}
                >
                  {getDateScaleLabel({
                    start_date,
                    end_date,
                    start_time,
                    end_time,
                    scale,
                  })}
                </div>
                {this.state.dateDialogState && (
                  <FixedDateScaleSettingDialog
                    defaults={{
                      start_date,
                      end_date,
                      start_time,
                      end_time,
                      scale,
                    }}
                    onClose={this.handleDateDialogClose}
                  />
                )}
                <AutoReloadButton
                  auto_reload_interval={this.state.auto_reload_interval}
                  enable_auto_reload={this.state.enable_auto_reload}
                  onAutoReloadChange={this.handleAutoReloadChange}
                  onAutoReloadIntervalChange={this.handleAutoReloadRangeChange}
                />
                {is_admin && (
                  <RoundedButton
                    text='ウィジェット作成'
                    onClick={this.handleDashboardWidgetCreateDialogOpen}
                    is_margin_left
                  />
                )}
              </div>
            </DashboardTopAreaWrapper>
            <Content>
              <DashboardWidgetsView
                {...this.props}
                start_date={start_date}
                end_date={end_date}
                start_time={start_time}
                end_time={end_time}
                dashboard={dashboard}
                metricStreams={this.metricStreams}
                playerStreams={this.playerStreams}
                autoReloadInterval={this.state.enable_auto_reload ? this.state.auto_reload_interval : -1}
                scale={this.state.scale}
              />
            </Content>
            {is_admin && this.state.is_widget_create_open ? (
              <CreateDashboardWidgetDialog
                {...this.props}
                isOpen={this.state.is_widget_create_open}
                dashboard={dashboard}
                onClose={this.handleDashboardWidgetCreateDialogClose}
                player_streams={this.playerStreams}
                metric_streams={this.metricStreams}
              />
            ) : null}
          </Content>
        )}
      </PageWholeArea>
    );
  }
}
