// -- basic library --
import React, { useCallback, useEffect, useState } from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox, { SelectData } from 'shared/components/atoms/SelectBox';
import Slider from 'shared/components/atoms/Slider';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { Footer, PopoverWholeArea } from 'shared/components/molecules/ContentsArea';
import topmenu_icon_question from 'assets/topmenu_icon_question.png';
import InputComponent from 'shared/components/molecules/InputComponent';
import InputDoubleBox from 'shared/components/molecules/InputDoubleBox';
import InputDoubleNumberBox from 'shared/components/molecules/InputDoubleNumberBox';
import styles from 'shared/styles/styles';
import { isPackageType, PackageType } from 'shared/models/PackageType';
import { StreamDataNumberType } from 'shared/models/StreamDataNumberType';
import { StreamDataType } from 'shared/models/StreamDataType';
import { StringBoolean, toStringBoolean } from 'shared/models/StringBoolean';
import { isValidNumber, isNotOnlySpace, validateDataNumberFromTo, isBetweenRange } from 'shared/utils/is';
import { dateToTimeStamp, dateToYMDHMS } from 'shared/utils/converter/date';
import { toISO8601 } from 'shared/models/ISO8601';

import { streamsIdGetAPI, Stream } from 'user/api/streams';
import {
  streamsIdPackagesPostAPI,
  streamsIdPackagesNumberGetAPI,
  StreamPackage,
  RequestStreamsIdPackagesPost,
} from 'user/api/streamsPackage';
import { InputJsonpathGroup } from 'user/dialogs/CsvRowsInputDialog/index';
import StreamPackageDetailWithSelector from 'user/dialogs/DetailStreamPackageDialog/StreamPackageDetailWithSelector';
import { isCsvEncodeType } from 'shared/models/CsvEncodeType';
import CSVTable from './CSVTable';
import { CsvRow } from 'shared/models/CsvRow';
import { jsonpathGroupsIdGetAPI } from 'admin/api/jsonpathGroups';
import validateCsvRows from 'user/utils/validateCsvRows';
import { JsonpathGroup } from 'user/api/jsonpathGroups';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { colors } from 'shared/styles/colors';
import {
  convertExportCsvStatistics,
  convertExportCsvStatisticsToSelectDatas,
  ExportCsvStatistics,
} from 'shared/models/ExportCsvStatistics';
import {
  convertExportCsvTimeUnit,
  convertExportCsvTimeUnitToSelectDatas,
  ExportCsvTimeUnit,
} from 'shared/models/ExportCsvTimeUnit';
import Popover from 'shared/components/atoms/Popover';
import styled from 'styled-components';
import IconButton from 'shared/components/molecules/IconButton';
import getDlpkgLabel from 'user/utils/getDlpkgLabel';
import RadioBox from 'shared/components/molecules/RadioBox';
import FunctionalText from 'shared/components/atoms/FunctionalText';

/**
 * csv_rowsがデフォルト値の時はtrue
 * @param csv_rows
 * @returns boolean
 */
const isDefaultCsvRows = (csv_rows: CsvRow[]) =>
  csv_rows.length === 1 &&
  csv_rows[0].header_name == '' &&
  csv_rows[0].json_path == '' &&
  csv_rows[0].cell_format == '' &&
  csv_rows[0].cell_format_args == '' &&
  csv_rows[0].statistic_method == 'Key' &&
  csv_rows[0].fill == 'False';

// -- types --
type Params = {
  isOpen: boolean;
  onClose: () => void;
  stream: Stream;
  stream_package_number?: string;
  jsonpath_group_id?: string;
};

// -- main component --
const CreateStreamDataDownloadPackagesDialog: React.FC<Params> = (params) => {
  // -- local states --
  const [origin_stream, setOriginStream] = useState<Stream | undefined>(undefined);
  const [stream_package_name, setStreamPackageName] = useState<string>('');
  const [stream_data_number_from, setStreamDataNumberFrom] = useState<string>('');
  const [stream_data_number_to, setStreamDataNumberTo] = useState<string>('');
  const [package_type, setPackageType] = useState<PackageType | ''>('');
  const [export_filename, setExportFilename] = useState<string>('');
  const [export_csv_encode, setExportCsvEncode] = useState<string>('shift_jis');
  const [export_csv_stream_data_number, setExportCsvStreamDataNumber] = useState<string>('Datetime');
  const [export_csv_statistics, setExportCsvStatistics] = useState<ExportCsvStatistics>('NONE');
  const [export_csv_time_unit, setExportCsvTimeUnit] = useState<ExportCsvTimeUnit | undefined>(undefined);
  const [favorite, setFavorite] = useState<boolean>(false);
  const [csv_rows, setCsvRows] = useState<CsvRow[]>([
    {
      header_name: '',
      json_path: '',
      cell_format: '',
      cell_format_args: '',
      statistic_method: 'Key',
      fill: 'False',
    },
  ]);
  const [fps, setFps] = useState<number>(1);
  const [width, setWidth] = useState<number>(400);
  const [height, setHeight] = useState<number>(400); // number
  const [is_preview_open, setIsPreviewOpen] = useState<boolean>(false);
  const [favorite_button_text, setFavoriteButtonText] = useState<string>('選択する');
  const [jsonpath_group_id, setJsonpathGroupId] = useState<string>('');
  const category = params.stream.category;
  /** export_auto_csv_type */
  const [export_auto_csv_type, setExportAutoCsvType] = useState<string>(
    getDefaultAutoCsvType(params.stream.auto_csv_type),
  );
  /** カウント・滞留・その他の分類 */
  const [export_auto_csv_type_kind] = useState<'NONE' | 'STAYCOUNT' | 'LINECOUNT'>(
    getDefaultAutoCsvTypeKind(params.stream.auto_csv_type),
  );

  // -- get external datas --
  // ストリームパッケージを上書きする
  const loadStreamPackage = async (args: {
    stream_id: string;
    data_number_type: StreamDataNumberType;
    stream_package_number: string;
  }) => {
    let data: StreamPackage | undefined = undefined;
    const res = await streamsIdPackagesNumberGetAPI({
      stream_id: args.stream_id,
      stream_package_number: args.stream_package_number,
      disabled_load: false,
    });
    if (res.status === 200) {
      data = res.data;
    }
    //それでもdataがなければ返す
    if (!data) {
      AlertDialog.show('ストリームパッケージを読み込めませんでした');
      return;
    }

    // 通信に成功したら、入力欄の初期値としてデータを導入
    setPackageType(data.package_type);
    setStreamPackageName(data.export_filename); // 画面にパッケージ名が非表示のためファイル名を採用します
    setExportFilename(data.export_filename);
    setExportCsvEncode(data.export_csv_encode);
    setExportCsvStreamDataNumber(data.export_csv_stream_data_number);
    if (data.jsonpath_group_id) {
      setJsonpathGroupId(data.jsonpath_group_id);
      const jsonpath_group = await jsonpathGroupsIdGetAPI({ jsonpath_group_id: data.jsonpath_group_id });
      setCsvRows(jsonpath_group.data.csv_rows);
    }
    setExportCsvStatistics(data.export_csv_statistics);
    setExportCsvTimeUnit(data.export_csv_time_unit);
    if (data.csv_rows.length > 0) {
      const new_csv_rows = data.csv_rows.map((r) => {
        return {
          header_name: r.header_name,
          json_path: r.json_path,
          cell_format: r.cell_format,
          cell_format_args: r.cell_format_args === null ? '' : r.cell_format_args,
          statistic_method: r.statistic_method || 'Key',
          fill: r.fill ? 'True' : ('False' as StringBoolean),
        };
      });
      setCsvRows(new_csv_rows);
    }
    if (+data.fps > 0) {
      setFps(+data.fps);
    }
    if (+data.width > 0) {
      setWidth(+data.width);
    }
    if (+data.height > 0) {
      setHeight(+data.height);
    }
    setExportAutoCsvType(data.export_auto_csv_type || 'LINECOUNT_PERSON');
    if (args.data_number_type === 'TIMESTAMP') {
      // origin_streamが存在しないなら問答無用でコピー
      if (!origin_stream) {
        setStreamDataNumberFrom(toISO8601(data.stream_data_number_from * 1000, 'NONE'));
        setStreamDataNumberTo(toISO8601(data.stream_data_number_to * 1000, 'NONE'));
      } else {
        // origin_streamが存在するなら、data_number_typeがTIMESTAMPならコピー
        if (origin_stream.data_number_type === 'TIMESTAMP') {
          setStreamDataNumberFrom(toISO8601(data.stream_data_number_from * 1000, 'NONE'));
          setStreamDataNumberTo(toISO8601(data.stream_data_number_to * 1000, 'NONE'));
        }
      }
    } else {
      // origin_streamが存在しないなら問答無用でコピー
      if (!origin_stream) {
        setStreamDataNumberFrom(String(data.stream_data_number_from));
        setStreamDataNumberTo(String(data.stream_data_number_to));
      } else {
        // origin_streamが存在するなら、data_number_typeがTIMESTAMP以外ならコピー
        if (origin_stream.data_number_type !== 'TIMESTAMP') {
          setStreamDataNumberFrom(String(data.stream_data_number_from));
          setStreamDataNumberTo(String(data.stream_data_number_to));
        }
      }
    }
  };
  // パッケージタイプを読み込む
  const loadPackageType = (data_type: StreamDataType) => {
    if (data_type === 'METRIC') {
      setPackageType('AUTO_CSV');
    }
    if (data_type === 'IMAGE' || data_type === 'VIDEO') {
      setPackageType('MP4');
    }
  };
  // -- handlers --

  // ストリームパッケージに基づきデータをコピーする
  const onStreamPackageCopy = async (stream_id: string, stream_package_number?: string, favorite_selected = true) => {
    const stream_res = await streamsIdGetAPI({ stream_id: stream_id });
    if (stream_res.status !== 200) return;
    const st = stream_res.data;
    // 最初のロードの時は、このストリームパッケージの元streamを読み込む
    if (!favorite_selected) {
      setOriginStream(st);
    }
    //ストリームのデータタイプからpackage_typeをセット
    loadPackageType(st.data_type);
    // stream_package_numberが存在する時はデータ上書き
    if (stream_package_number) {
      loadStreamPackage({
        stream_id: stream_id,
        stream_package_number: stream_package_number,
        data_number_type: st.data_number_type,
      });
    }
    // お気に入りを選ばれた時はボタンの文字を変更
    if (favorite_selected) {
      setFavoriteButtonText('選択済み');
    }
    // loading終了
  };

  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  const validate = () => {
    if (!isPackageType(package_type)) {
      throw new Error(`パッケージタイプが無効です\nストリームのデータの情報が画像、動画\nメトリクスのみ有効です。`);
    }
    // 対象データのバリデーション
    validateDataNumberFromTo({
      data_number_type: origin_stream?.data_number_type,
      stream_data_number_from,
      stream_data_number_to,
    });
    // 属性区分切替日がある場合は期間を跨いでいないかチェック
    if (origin_stream?.data_number_type === 'TIMESTAMP' && origin_stream?.type_changed_at) {
      validateNotIncludeDate(stream_data_number_from, stream_data_number_to, origin_stream.type_changed_at);
    }
    if (!isNotOnlySpace(export_filename)) {
      throw new Error('出力ファイル名は必須です');
    }
    if (package_type === 'CSV') {
      if (!isCsvEncodeType(export_csv_encode)) {
        throw new Error('CSVエンコードは必須です');
      }
      validateCsvRows(csv_rows);
    }
    if (package_type === 'AUTO_CSV') {
      if (!isCsvEncodeType(export_csv_encode)) {
        throw new Error('CSVエンコードは必須です');
      }
    }
    // MP4時のヴァリデーション
    if (package_type === 'MP4') {
      if (!isBetweenRange(fps, 1, 30)) {
        throw new Error('FPSは必須です\n※1以上30以下');
      }
      if (
        !isValidNumber({
          num: width,
          positive: true,
        })
      ) {
        throw new Error('幅は必須です\n※1以上の整数');
      }
      if (
        !isValidNumber({
          num: height,
          positive: true,
        })
      ) {
        throw new Error('高さは必須です\n※1以上の整数');
      }
    }
  };

  const handleFinishClick = async (stream_id: string, stream: Stream | undefined) => {
    try {
      validate();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
    const is_time_stamp = stream?.data_number_type === 'TIMESTAMP';
    const is_csv = package_type === 'CSV' || package_type === 'AUTO_CSV';
    const is_mp4 = package_type === 'MP4';
    const is_stay_count = export_auto_csv_type === 'STAYCOUNT';
    // 集計でのJSONパスチェックはCSVのみ（AUTO_CSVは対象外）
    if (export_csv_statistics === 'TIMESTAMP' && package_type === 'CSV') {
      let timestamp_flag = false;
      for (const csv_row of csv_rows) {
        if (csv_row.json_path.slice(-2) === '.t' || csv_row.json_path.slice(-3) === '.ts') {
          timestamp_flag = true;
          break;
        }
      }
      if (!timestamp_flag) {
        AlertDialog.show(
          '集計する時間を設定する場合、\nJSONパスに「.t」または「.ts」で終わる行を設定しないと集計が行えません。 ',
        );
        return;
      }
    }

    const streams_pakages_post_datas: RequestStreamsIdPackagesPost = {
      stream_id,
      stream_package_name,
      stream_data_number_from: is_time_stamp
        ? String(dateToTimeStamp(stream_data_number_from))
        : stream_data_number_from,
      stream_data_number_to: is_time_stamp ? String(dateToTimeStamp(stream_data_number_to)) : stream_data_number_to,
      // validate()でpackage_typeの判定は完了済み
      package_type: package_type as PackageType,
      export_filename,
      favorite: toStringBoolean(favorite),
      fps: is_mp4 ? String(fps) : undefined,
      width: is_mp4 ? String(width) : undefined,
      height: is_mp4 ? String(height) : undefined,
      jsonpath_group_id: is_csv ? jsonpath_group_id : undefined,
      export_csv_encode: is_csv ? export_csv_encode : undefined,
      csv_rows: is_csv ? csv_rows : undefined,
      export_csv_stream_data_number: is_csv ? export_csv_stream_data_number : undefined,
      export_csv_statistics: !is_stay_count ? export_csv_statistics : 'NONE',
      export_csv_time_unit: !is_stay_count ? export_csv_time_unit : undefined,
      export_auto_csv_type: export_auto_csv_type,
    };

    const res = await streamsIdPackagesPostAPI(streams_pakages_post_datas);
    if (res.status === 200) {
      params.onClose();
      AlertDialog.show(getDlpkgLabel(params.stream) + 'の追加に成功しました');
    }
  };

  // JSONパスグループを選択を変更したときのイベントハンドラ
  const handleJsonpathGroupChange = (jsonpath_group?: JsonpathGroup) => {
    // JSONパスグループを未選択以外に設定し、CSV定義を設定している場合、ポップアップを表示
    if (!jsonpath_group || csv_rows.length == 0 || isDefaultCsvRows(csv_rows)) {
      setJsonpathGroupId(jsonpath_group?.jsonpath_group_id ?? '');
      if (jsonpath_group?.jsonpath_group_id) {
        setCsvRows(jsonpath_group.csv_rows);
        setExportCsvStatistics(jsonpath_group.export_csv_statistics);
      }
    } else {
      ConfirmDialog.show(
        <div style={{ color: colors.red }}>
          [確認]編集しているCSV定義をリセットします。
          <br />
          本当によろしいですか?
        </div>,
        () => handleOKClick(jsonpath_group),
        () => {},
        undefined,
      );
    }
  };

  // 集計の設定を変更したときの関数
  const handleStatisticsChangeClick = (export_csv_statistics: ExportCsvStatistics | undefined) => {
    if (export_csv_statistics) setExportCsvStatistics(export_csv_statistics);
    if (export_csv_statistics === 'TIMESTAMP') {
      // 時刻集計のときはA列にタイムスタンプを出力しない
      setExportCsvStreamDataNumber('None');
      setExportCsvTimeUnit('MINUTE');
    } else {
      // 時刻集計以外のときは時間単位を空にする
      setExportCsvTimeUnit(undefined);
    }
  };

  // ポップアップでOK押下した場合、JSONパスグループを変更し、CSV定義を上書きする関数
  const handleOKClick = async (jsonpath_group?: JsonpathGroup) => {
    setJsonpathGroupId(jsonpath_group?.jsonpath_group_id ?? '');
    if (jsonpath_group?.jsonpath_group_id) {
      setCsvRows(jsonpath_group.csv_rows);
      setExportCsvStatistics(jsonpath_group.export_csv_statistics);
    }
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      await onStreamPackageCopy(params.stream.stream_id, params.stream_package_number, false);
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PfDialog title={getDlpkgLabel(params.stream) + '作成'} onClose={params.onClose} isOpen={params.isOpen} large>
      {origin_stream === undefined ? (
        <Spinner />
      ) : (
        <>
          <InputComponent text='お気に入りパッケージ選択'>
            <RoundedButton onClick={() => setIsPreviewOpen(true)} text={favorite_button_text} />
          </InputComponent>
          <InputComponent text='お気に入り'>
            <CheckBoxWithText
              text='登録する'
              checked={favorite}
              onClick={() => setFavorite(!favorite)}
              data-testid='favorite_registration'
            />
          </InputComponent>
          <InputComponent text='対象データ' required>
            {origin_stream.data_number_type === 'TIMESTAMP' ? (
              <InputDoubleBox
                placeholders={['入力してください', '入力してください']}
                value1={stream_data_number_from}
                value2={stream_data_number_to}
                handleChangeClick1={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { value } = e.currentTarget;
                  setStreamDataNumberFrom(value);
                }}
                handleChangeClick2={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { value } = e.currentTarget;
                  setStreamDataNumberTo(value);
                }}
                interval_text='〜'
                types={['datetime-local', 'datetime-local']}
                steps={[1, 1]}
                test_ids={['stream_data_number_from', 'stream_data_number_to']}
              />
            ) : (
              <InputDoubleBox
                placeholders={['入力してください', '入力してください']}
                value1={stream_data_number_from}
                value2={stream_data_number_to}
                handleChangeClick1={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setStreamDataNumberFrom(e.currentTarget.value)
                }
                handleChangeClick2={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setStreamDataNumberTo(e.currentTarget.value)
                }
                interval_text='~'
                types={['string', 'string']}
                test_ids={['stream_data_number_from', 'stream_data_number_to']}
              />
            )}
            {origin_stream.data_number_type === 'TIMESTAMP' && origin_stream.type_changed_at && (
              <>データ期間は {dateToYMDHMS(origin_stream.type_changed_at * 1000)} を跨いで選択できません。</>
            )}
          </InputComponent>
          <InputComponent text='出力ファイル名' required>
            <InputBox
              data-testid='export_filename'
              placeholder='入力してください'
              value={export_filename}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setExportFilename(e.currentTarget.value);
                setStreamPackageName(e.currentTarget.value);
              }}
            />
          </InputComponent>
          {package_type === 'AUTO_CSV' && (
            <AutoCsvTypeAdditionalForm
              export_csv_encode={export_csv_encode}
              onExportCsvEncodeChange={setExportCsvEncode}
              export_auto_csv_type_kind={export_auto_csv_type_kind}
              export_auto_csv_type={export_auto_csv_type}
              onExportAutoCsvTypeChange={setExportAutoCsvType}
              onCsvTypeToggle={() => setPackageType('CSV')}
              export_csv_statistics={export_csv_statistics}
              onExportCsvStatisticsChange={(value) => handleStatisticsChangeClick(value)}
              export_csv_time_unit={export_csv_time_unit}
              onExportCsvTimeUnitChange={(value) => setExportCsvTimeUnit(value)}
              export_csv_stream_data_number={export_csv_stream_data_number}
              onExportCsvStreamDataNumberChange={(value) => setExportCsvStreamDataNumber(value)}
            />
          )}
          {package_type === 'CSV' && (
            <>
              <InputComponent text='CSVエンコード' required>
                <RadioBox
                  datas={[
                    { name: 'shift_jis [標準]', value: 'shift_jis' },
                    { name: 'UTF-8', value: 'UTF-8' },
                  ]}
                  selectedValue={export_csv_encode}
                  handleChangeClick={setExportCsvEncode}
                  data-testid='export_csv_encode'
                />
              </InputComponent>
              <InputComponent text='JSONパスグループ'>
                <InputJsonpathGroup
                  value={jsonpath_group_id}
                  onChange={(e) => handleJsonpathGroupChange(e)}
                  display={'STREAM'}
                  category={category}
                />
              </InputComponent>
              {/* 「集計」フィールド */}
              <ExportCsvStatisticsForm
                export_csv_statistics={export_csv_statistics}
                exportCsvStaticsKeys={['NONE', 'TIMESTAMP', 'KEY']}
                onExportCsvStatisticsChange={(value) => handleStatisticsChangeClick(value)}
                export_csv_time_unit={export_csv_time_unit}
                onExportCsvTimeUnitChange={(value) => setExportCsvTimeUnit(value)}
                export_csv_stream_data_number={export_csv_stream_data_number}
                onExportCsvStreamDataNumberChange={(value) => setExportCsvStreamDataNumber(value)}
                showExportCsvStreamDataNumber={true}
                export_auto_csv_type={export_auto_csv_type}
              />
              <InputComponent text='CSV定義' required>
                <CSVTable
                  bodies={csv_rows}
                  setCsvRows={setCsvRows}
                  stream_id={params.stream.stream_id}
                  showStatistics={export_csv_statistics === 'KEY'}
                  table_area_props={{
                    style: {
                      maxHeight: 200,
                    },
                  }}
                />
              </InputComponent>
            </>
          )}
          {package_type === 'MP4' ? (
            <>
              <InputComponent text='FPS' required>
                <Slider
                  value={fps}
                  onChange={(value: number) => setFps(value)}
                  stepSize={1}
                  labelValues={[1, 10, 20, 30]}
                  max={30}
                  min={1}
                />
              </InputComponent>
              <InputComponent text='解像度' required>
                <InputDoubleNumberBox
                  placeholders={['入力してください', '入力してください']}
                  value1={width}
                  value2={height}
                  handleChangeClick1={setWidth}
                  handleChangeClick2={setHeight}
                  interval_text='×'
                  value_texts={['幅', '高さ']}
                />
              </InputComponent>
            </>
          ) : null}
          {is_preview_open && (
            <StreamPackageDetailWithSelector
              isOpen={is_preview_open}
              onClose={() => setIsPreviewOpen(false)}
              onCopyCreate={onStreamPackageCopy}
              style={{ width: '85vw' }}
              stream={params.stream}
            />
          )}
          <Footer>
            <RoundedButton
              onClick={params.onClose}
              text='キャンセル'
              style={{ marginRight: styles.interval_margin }}
              is_white
            />
            <RoundedButton
              onClick={() => handleFinishClick(params.stream.stream_id, origin_stream)}
              text_type='CREATE'
            />
          </Footer>
        </>
      )}
    </PfDialog>
  );
};

type AutoCsvTypeAdditionalFormParam = {
  export_csv_encode: string;
  onExportCsvEncodeChange: (value: string) => void;
  export_auto_csv_type_kind: 'NONE' | 'STAYCOUNT' | 'LINECOUNT';
  export_auto_csv_type: string;
  onExportAutoCsvTypeChange: (value: string) => void;
  onCsvTypeToggle: () => void;
  export_csv_statistics: ExportCsvStatistics;
  onExportCsvStatisticsChange: (value: ExportCsvStatistics | undefined) => void;
  export_csv_time_unit: ExportCsvTimeUnit | undefined;
  onExportCsvTimeUnitChange: (value: ExportCsvTimeUnit | undefined) => void;
  export_csv_stream_data_number: string;
  onExportCsvStreamDataNumberChange: (value: string) => void;
};
const AutoCsvTypeAdditionalForm: React.FC<AutoCsvTypeAdditionalFormParam> = (params) => {
  const getCsvType1 = () => {
    const ret: SelectData<string>[] = [];
    if (params.export_auto_csv_type_kind === 'NONE' || params.export_auto_csv_type_kind === 'LINECOUNT') {
      ret.push({ name: 'カウント', value: 'LINECOUNT' });
    }
    if (params.export_auto_csv_type_kind === 'NONE' || params.export_auto_csv_type_kind === 'STAYCOUNT') {
      ret.push({ name: '滞留', value: 'STAYCOUNT' });
    }
    return ret;
  };
  const getCsvType1Value = () => {
    if (params.export_auto_csv_type.startsWith('LINECOUNT_')) {
      return 'LINECOUNT';
    } else {
      return 'STAYCOUNT';
    }
  };
  const handleCsvType1Click = (value: string) => {
    if (value === 'LINECOUNT') {
      if (!params.export_auto_csv_type.startsWith('LINECOUNT_')) {
        params.onExportAutoCsvTypeChange('LINECOUNT_PERSON');
      }
    } else if (value === 'STAYCOUNT') {
      params.onExportAutoCsvTypeChange('STAYCOUNT');
    }
  };
  const handlePersonClick = () => {
    if (params.export_auto_csv_type === 'LINECOUNT_PERSON_CAR') {
      params.onExportAutoCsvTypeChange('LINECOUNT_CAR');
    } else if (params.export_auto_csv_type === 'LINECOUNT_CAR') {
      params.onExportAutoCsvTypeChange('LINECOUNT_PERSON_CAR');
    }
  };
  const handleCarClick = () => {
    if (params.export_auto_csv_type === 'LINECOUNT_PERSON_CAR') {
      params.onExportAutoCsvTypeChange('LINECOUNT_PERSON');
    } else if (params.export_auto_csv_type === 'LINECOUNT_PERSON') {
      params.onExportAutoCsvTypeChange('LINECOUNT_PERSON_CAR');
    }
  };
  return (
    <>
      <InputComponent text='CSVエンコード' required>
        <RadioBox
          datas={[
            { name: 'shift_jis [標準]', value: 'shift_jis' },
            { name: 'UTF-8', value: 'UTF-8' },
          ]}
          selectedValue={params.export_csv_encode}
          handleChangeClick={params.onExportCsvEncodeChange}
          data-testid='export_csv_encode'
        />
      </InputComponent>
      <InputComponent text='CSV形式' required>
        <RadioBox
          datas={getCsvType1()}
          selectedValue={getCsvType1Value()}
          handleChangeClick={handleCsvType1Click}
          data-testid='export_auto_csv_type1'
        />
        {params.export_auto_csv_type.startsWith('LINECOUNT_') && (
          <>
            <CheckBoxWithText
              checked={params.export_auto_csv_type.includes('PERSON')}
              text={'人'}
              onClick={() => handlePersonClick()}
            />
            <span>&nbsp;</span>
            <CheckBoxWithText
              checked={params.export_auto_csv_type.includes('CAR')}
              text={'車'}
              onClick={() => handleCarClick()}
            />
          </>
        )}
      </InputComponent>
      {/* 「集計」フィールド */}
      <ExportCsvStatisticsForm
        export_csv_statistics={params.export_csv_statistics}
        exportCsvStaticsKeys={['NONE', 'TIMESTAMP']}
        onExportCsvStatisticsChange={params.onExportCsvStatisticsChange}
        export_csv_time_unit={params.export_csv_time_unit}
        onExportCsvTimeUnitChange={params.onExportCsvTimeUnitChange}
        export_csv_stream_data_number={params.export_csv_stream_data_number}
        onExportCsvStreamDataNumberChange={params.onExportCsvStreamDataNumberChange}
        showExportCsvStreamDataNumber={false}
        export_auto_csv_type={params.export_auto_csv_type}
      />
      <div style={{ textAlign: 'right' }}>
        <FunctionalText text={'JSONパスグループによる設定に切り替える'} onClick={params.onCsvTypeToggle} />
      </div>
    </>
  );
};

type ExportCsvStatisticsFormParam = {
  export_csv_statistics: ExportCsvStatistics;
  exportCsvStaticsKeys: ExportCsvStatistics[];
  onExportCsvStatisticsChange: (value: ExportCsvStatistics | undefined) => void;
  export_csv_time_unit: ExportCsvTimeUnit | undefined;
  onExportCsvTimeUnitChange: (value: ExportCsvTimeUnit | undefined) => void;
  export_csv_stream_data_number: string;
  onExportCsvStreamDataNumberChange: (value: string) => void;
  showExportCsvStreamDataNumber: boolean;
  export_auto_csv_type: string;
};
/** 「集計」フィールド */
const ExportCsvStatisticsForm: React.FC<ExportCsvStatisticsFormParam> = (params) => {
  const [is_open_menu_popover, setIsOpenMenuPopover] = useState<boolean>(false);
  const open = useCallback(() => {
    setIsOpenMenuPopover(true);
  }, []);
  const close = useCallback(() => {
    setIsOpenMenuPopover(false);
  }, []);
  const is_timestamp = params.export_csv_statistics === 'TIMESTAMP';
  // STAYCOUNT(滞留)の時は表示させない
  if (params.export_auto_csv_type === 'STAYCOUNT') return null;

  return (
    <>
      <InputComponent text='集計'>
        <SelectBox
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            params.onExportCsvStatisticsChange(convertExportCsvStatistics(e.currentTarget.value))
          }
          value={params.export_csv_statistics}
          default_text='null'
          datas={convertExportCsvStatisticsToSelectDatas(params.exportCsvStaticsKeys)}
        />
      </InputComponent>
      {is_timestamp ? (
        <InputComponent text='集計する時間単位'>
          <TimeUnitArea>
            <SelectBox
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                params.onExportCsvTimeUnitChange(convertExportCsvTimeUnit(e.currentTarget.value))
              }
              value={params.export_csv_time_unit ?? ''}
              default_text='null'
              datas={convertExportCsvTimeUnitToSelectDatas()}
            />
            <Popover
              content={
                <QuestionPopoverWholeArea>
                  A列には、集計する時間帯の開始時刻が出力されます。
                  <br />
                  <br />
                  [集計単位が1時間] A列に2023/04/01 13:00:00と出力されている場合
                  <br />
                  2023/04/01 13:00:00から2023/04/01 13:59:59までの集計結果となります。
                  <br />
                  <br />
                  [集計単位が1日] A列に2023/04/01 0:00:00と出力されている場合
                  <br />
                  2023/04/01 0:00:00から2023/04/01 23:59:59までの集計結果となります。
                </QuestionPopoverWholeArea>
              }
              isOpen={is_open_menu_popover}
              onClose={close}
            >
              <IconButton
                img_src={topmenu_icon_question}
                style={{ width: 25, height: 25, marginLeft: 10 }}
                handleClick={open}
              />
            </Popover>
          </TimeUnitArea>
        </InputComponent>
      ) : (
        params.showExportCsvStreamDataNumber && (
          <InputComponent text='A列にタイムスタンプを出力'>
            <CheckBoxWithText
              text='出力する'
              checked={params.export_csv_stream_data_number !== 'None'}
              onClick={() =>
                params.onExportCsvStreamDataNumberChange(
                  params.export_csv_stream_data_number !== 'None' ? 'None' : 'Datetime',
                )
              }
            />
          </InputComponent>
        )
      )}
    </>
  );
};

/** type_changed_at を跨いでいないかチェックします。跨いでいる場合はエラーとします。
 *
 * type_changed_at は新しい区分の開始時刻なので、
 * - type_changed_at < from < to は OK
 * - from = type_changed_at < to は OK
 * - from < type_changed_at = to は NG
 * - from < type_changed_at < to は NG
 * - from < to < type_changed_at は OK
 */
function validateNotIncludeDate(
  stream_data_number_from: string,
  stream_data_number_to: string,
  type_changed_at: number,
) {
  const a = dateToTimeStamp(stream_data_number_from);
  const b = dateToTimeStamp(stream_data_number_to);
  if (a < type_changed_at && type_changed_at <= b) {
    const c = dateToYMDHMS(type_changed_at * 1000);
    throw new Error(`対象データは ${c} を跨いで指定できません。`);
  }
}

/** ストリームの auto_csv_type から画面上のデフォルトの選択肢を返します。
 * auto_csv_type が未設定や NONE の場合は LINECOUNT_PERSON を初期値にします。
 */
function getDefaultAutoCsvType(auto_csv_type: string | null | undefined) {
  switch (auto_csv_type) {
    case 'LINECOUNT_PERSON':
    case 'LINECOUNT_CAR':
    case 'LINECOUNT_PERSON_CAR':
    case 'STAYCOUNT':
      return auto_csv_type;
    default:
      return 'LINECOUNT_PERSON';
  }
}
/** カウント・滞留・その他 の分類を返します。 */
function getDefaultAutoCsvTypeKind(auto_csv_type: string | null | undefined) {
  switch (auto_csv_type) {
    case 'LINECOUNT_PERSON':
    case 'LINECOUNT_CAR':
    case 'LINECOUNT_PERSON_CAR':
      return 'LINECOUNT';
    case 'STAYCOUNT':
      return 'STAYCOUNT';
    default:
      return 'NONE';
  }
}

// -- styled components --

const TimeUnitArea = styled.div`
  display: flex;
  align-items: center;
`;

const QuestionPopoverWholeArea = styled(PopoverWholeArea)`
  width: 530px;
  height: 160px;
`;

// -- finally export part --

export default CreateStreamDataDownloadPackagesDialog;
