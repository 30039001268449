import { DashboardBusOdMetric } from 'user/api/dashboardsWidgets';
import SelectBox, { SelectData } from 'shared/components/atoms/SelectBox';
import Spinner from 'shared/components/atoms/Spinner';
import { CartesianGragh, CartesianGraphData, SymbolType } from 'shared/components/molecules/PfGraph';
import React from 'react';
import DateRangeWidgetBase2 from './base/DateRangeWidgetBase2';
import { WidgetProps } from './getWidgetType';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';

interface Busod2WidgetState {
  data?: DashboardBusOdMetric[];
  route_long_name: string;
  stat_age: boolean;
  stat_gender: boolean;
}
/**
 * バス乗降グラフ描画ウィジェットです。
 */
export default class Busod2Widget extends React.PureComponent<WidgetProps, Busod2WidgetState> {
  constructor(props: WidgetProps) {
    super(props);
    this.state = {
      data: undefined,
      route_long_name: '',
      stat_age: props.widget.od_age || false,
      stat_gender: props.widget.od_gender || false,
    };
  }
  private getLabel(o: { label?: string; gender?: {name: string}; age?: {name: string}; od: {state: string}; }) {
    let label = o.od.state;
    if (this.state.stat_gender) {
      label += '/' + o.gender?.name;
    }
    if (this.state.stat_age) {
      label += '/' + o.age?.name;
    }
    return label;
  }
  private getSymbolTypes(data: DashboardBusOdMetric[]): SymbolType[] {
    const labels = this.getLabels(data);
    return Array.from(labels).map((e) => {
      return {
        dataKey: e,
        name: e,
        type: 'BAR',
        stackId: '1',
      };
    });
  }
  private getLabels(data: DashboardBusOdMetric[]): Set<string> {
    const ret: Set<string> = new Set();
    for (const item of data) {
      for (const o of item.objects) {
        if (o.od.state === 'IN') {
          const label = this.getLabel(o);
          ret.add(label);
        }
      }
    }
    return ret;
  }
  private getData(data: DashboardBusOdMetric[]): CartesianGraphData[] {
    const ret: CartesianGraphData[] = [];
    const hashedData: { [dataKey: string]: CartesianGraphData } = {}; // stop_name -> CartesianGraphData
    for (const item of data) {
      // 路線名を取得
      const route_long_name = item.gtfs?.route_long_name || 'Unknown';
      if (this.state.route_long_name !== '' && this.state.route_long_name !== route_long_name) {
        continue;
      }
      // バス停名を取得
      const date_label = getDateLabel(item.t);
      // グラフ要素を取得(無ければ作成)
      let data = hashedData[date_label];
      if (data === undefined) {
        data = { datapoint: date_label };
        hashedData[date_label] = data;
        ret.push(data);
      }
      // ラベル要素に加算
      for (const o of item.objects) {
        if (o.od.state === 'IN') {
          const label = this.getLabel(o);
          let value = data[label];
          if (value === undefined) {
            value = 0;
          }
          data[label] = Number(value) + 1;
        }
      }
    }
    return ret;
  }
  /** 路線名セレクトボックスの選択肢を返します。 */
  private getSelectBoxData(data: DashboardBusOdMetric[]): SelectData<string>[] {
    const ret: SelectData<string>[] = [{ name: '全て', value: '' }];
    const s: Set<string> = new Set();
    for (const item of data) {
      if (item.gtfs) {
        const route_long_name = item.gtfs.route_long_name;
        if (!s.has(route_long_name)) {
          s.add(route_long_name);
          ret.push({ name: route_long_name, value: route_long_name });
        }
      }
    }
    return ret;
  }
  private onMetricsChanged = (data: DashboardBusOdMetric[]) => {
    this.setState({ data });
  };
  private onInitMetrics = () => {
    this.setState({ data: undefined });
  };
  render() {
    const { data } = this.state;
    return (
      <DateRangeWidgetBase2
        widget_type={'BUSOD2'}
        widget={this.props.widget}
        state={this.props.state}
        data={this.state.data}
        route_long_name={this.state.route_long_name}
        onInitMetrics={this.onInitMetrics}
        onStateChanged={this.props.onStateChanged}
        onMetricsChanged={this.onMetricsChanged}
      >
        {data ? (
          <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div>
                <SelectBox
                  onChange={(e) => this.setState({ route_long_name: e.currentTarget.value })}
                  value={this.state.route_long_name}
                  datas={this.getSelectBoxData(data)}
                  default_text={'null'} />
              </div>
              <div style={{margin: 'auto 1px auto 1px'}}>
                <CheckBoxWithText checked={this.state.stat_gender} text={'性別'} onClick={() => this.setState({stat_gender: !this.state.stat_gender})} />
              </div>
              <div style={{margin: 'auto 1px auto 1px'}}>
                <CheckBoxWithText checked={this.state.stat_age} text={'年代'} onClick={() => this.setState({stat_age: !this.state.stat_age})} />
              </div>
            </div>
            <div style={{ flexGrow: 1 }}>
              <CartesianGragh
                symbolTypes={this.getSymbolTypes(data)}
                data={this.getData(data)}
                isTimestampTick={false}
              />
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </DateRangeWidgetBase2>
    );
  }
}

function getDateLabel(t: number) {
  const date = new Date(t * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return year + '/' + (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day);
}
