import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';
import { OrderBy } from 'shared/models/OrderBy';

/*** エンティティ ***/

export interface ChannelEvent {
  tenant_id: string;
  channel_id: string;
  channel_event_number: number;
  channel_event_name: string;
  latest_comment: {
    user_id: string;
    user_name: string;
    comment: string;
    created_at: ISO8601;
  } | null;
  confirm: {
    user_id: string;
    user_name: string;
    confirmed_at: ISO8601;
  } | null;
  video_clip?: {
    stream_id: string;
    clip_status: 'READY' | 'PROGRESS';
    periods_before: number | null;
    periods_after: number | null;
  };
  stayer_area?: string;
  stayer_label?: string;
  stayer_time?: number;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface ChannelEventsWithPaging {
  items: ChannelEvent[];
  has_next: boolean;
  esk: number | null;
}

const sample_channel_events_1: ChannelEvent = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_event_number: 1,
  channel_event_name: 'チャンネルイベント１',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  latest_comment: {
    user_id: 'aaa',
    user_name: '長谷川',
    comment: 'これご検知でした~',
    created_at: '2021-06-01T00:00:00+09:00' as ISO8601,
  },
  video_clip: {
    stream_id: '000000000600',
    clip_status: 'READY',
    periods_before: 30,
    periods_after: 90,
  },
  confirm: null,
};

const sample_channel_events_2: ChannelEvent = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_event_number: 2,
  channel_event_name: 'チャンネルイベント２',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  latest_comment: {
    user_id: 'aaa',
    user_name: '長谷川',
    comment: 'これご検知でした~',
    created_at: '2021-06-01T00:00:00+09:00' as ISO8601,
  },
  confirm: {
    user_id: 'bbb',
    user_name: '田中',
    confirmed_at: '2021-06-01T00:00:00+09:00' as ISO8601,
  },
};

const sample_channel_events_3: ChannelEvent = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_event_number: 3,
  channel_event_name: 'チャンネルイベント３',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  latest_comment: {
    user_id: 'aaa',
    user_name: '長谷川',
    comment: 'これご検知でした~',
    created_at: '2021-06-01T00:00:00+09:00' as ISO8601,
  },
  confirm: {
    user_id: 'bbb',
    user_name: '田中',
    confirmed_at: '2021-06-01T00:00:00+09:00' as ISO8601,
  },
};

const sample_channel_events_4: ChannelEvent = {
  tenant_id: '00000001',
  channel_id: '00000003',
  channel_event_number: 4,
  channel_event_name: 'チャンネルイベント4',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  latest_comment: {
    user_id: 'aaa',
    user_name: '長谷川',
    comment: 'これご検知でした~',
    created_at: '2021-06-01T00:00:00+09:00' as ISO8601,
  },
  confirm: {
    user_id: 'bbb',
    user_name: '田中',
    confirmed_at: '2021-06-01T00:00:00+09:00' as ISO8601,
  },
};

const sample_channel_events_5: ChannelEvent = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_event_number: 5,
  channel_event_name: 'チャンネルイベント5',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  latest_comment: {
    user_id: 'aaa',
    user_name: '長谷川',
    comment: 'これご検知でした~',
    created_at: '2021-06-01T00:00:00+09:00' as ISO8601,
  },
  confirm: {
    user_id: 'bbb',
    user_name: '田中',
    confirmed_at: '2021-06-01T00:00:00+09:00' as ISO8601,
  },
};

/** 動画再生のURL生成 */
export function channelsIdEventNumberM3u8Url(channelId: string, channelEventNumber: number) {
  return '/api/channels/' + channelId + '/events/' + channelEventNumber + '/play.m3u8';
}

/*** [GET] /api/channels/{channel_id}/events ***/

export interface RequestChannelsIdEventsGet extends CommonAPIRequestType {
  channel_id: string;
  exclusive_start_channel_event_number?: number;
  limit?: number;
  order?: OrderBy;
}

/**
 * チャンネルに設定されたイベントの一覧を取得する
 * @param channel_id チャンネルID
 * @param exclusive_start_channel_event_number 開始チャンネルイベント番号
 * @param order 検索方法
 * @returns チャンネルのプロセスの一覧
 */
export const channelsIdEventsGetAPI = (params: RequestChannelsIdEventsGet) => {
  const { channel_id, exclusive_start_channel_event_number, limit, order = 'ASC' } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/events`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    exclusive_start_channel_event_number,
    order,
    limit,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ChannelEventsWithPaging>(axios, path, query, form, method, {
    items: [
      sample_channel_events_1,
      sample_channel_events_2,
      sample_channel_events_3,
      sample_channel_events_4,
      sample_channel_events_5,
    ],
    has_next: false,
    esk: 1,
  });
};

/*** [POST] /api/channels/{channel_id}/events ***/

/**
 * チャンネルに設定されたプロセスの一覧を取得する
 * @param channel_id チャンネルID
 * @returns チャンネルのプロセスの一覧
 */

export interface RequestChannelsIdEventsPost extends CommonAPIRequestType {
  channel_id: string;
  channel_event_name: string;
}

export const channelsIdEventsPostAPI = (params: RequestChannelsIdEventsPost) => {
  const { channel_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/events`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'channel_id' || isCommonAPIRequestType(key)) {
      continue;
    }
    form.append(key, value);
  }

  // 送信
  return sendAxios<ChannelEvent>(axios, path, query, form, method, sample_channel_events_1);
};

/*** [DELETE] /api/channels/{channel_id}/events ***/

/**
 * チャンネルに設定されたプロセスの一覧を削除する
 * @param channel_id チャンネルID
 * @returns チャンネルのプロセスの一覧
 */

export interface RequestChannelsIdEventsChannelEventNumberDelete extends CommonAPIRequestType {
  channel_id: string;
  channel_event_number: string;
}

export const channelsIdEventsChannelEventNumberDeleteAPI = (
  params: RequestChannelsIdEventsChannelEventNumberDelete,
) => {
  const { channel_id, channel_event_number, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/events/${channel_event_number}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ChannelEvent>(axios, path, query, form, method, sample_channel_events_1);
};

/*** [GET] /api/channels/{channel_id}/events/{channel_event_number} ***/

/**
 * チャンネルと指定のchannel_event_numberのイベントを取得する
 * @param channel_id チャンネルID
 * @returns チャンネルのイベント
 */

export interface RequestChannelsIdEventsChannelEventNumberGet {
  channel_id: string;
  channel_event_number: string | number;
}

export const channelsIdEventsChannelEventNumberGetAPI = (params: RequestChannelsIdEventsChannelEventNumberGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/channels/${params.channel_id}/events/${params.channel_event_number}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   if(key === 'channel_id'){
  //     continue;
  //   }
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ChannelEvent>(axios, path, query, form, method, sample_channel_events_1);
};

/*** [PUT] /api/channels/{channel_id}/events/{channel_event_number} ***/

/**
 * チャンネルイベントを変更する
 * @param channel_id チャンネルID
 * @returns チャンネルのイベント
 */

export interface RequestChannelsIdEventsChannelEventNumberPut extends CommonAPIRequestType {
  channel_id: string;
  channel_event_number: string;
  channel_event_name: string;
}

export const channelsIdEventsChannelEventNumberPutAPI = (params: RequestChannelsIdEventsChannelEventNumberPut) => {
  const { channel_id, channel_event_number, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/events/${channel_event_number}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'channel_id' || key === 'channel_event_number' || isCommonAPIRequestType(key)) {
      continue;
    }
    form.append(key, value);
  }

  // 送信
  return sendAxios<ChannelEvent>(axios, path, query, form, method, sample_channel_events_1);
};

/*** [POST] /api/channels/{channel_id}/events/{channel_event_number}/confirm ***/

/**
 * チャンネルイベントを確認済みにする
 * @param channel_id チャンネルID
 * @param channel_event_number チャンネルイベント番号
 * @returns チャンネルのイベント
 */

export interface RequestChannelsIdEventsChannelEventNumberConfirmPost extends CommonAPIRequestType {
  channel_id: string;
  channel_event_number: string | number;
}

export const channelsIdEventsChannelEventNumberConfirmPostAPI = (
  params: RequestChannelsIdEventsChannelEventNumberConfirmPost,
) => {
  const { channel_id, channel_event_number, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/events/${channel_event_number}/confirm`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<ChannelEvent>(axios, path, query, form, method, sample_channel_events_1);
};
