import React from 'react';
import DescriptionText from 'shared/components/atoms/DescriptionText';
import LiveTextSettingPane, { LiveTextSettingPaneValues } from 'shared/components/molecules/LiveTextPropertiesPane';
import styles from 'shared/styles/styles';
import styled from 'styled-components';
import { Metric } from 'user/api/dashboardsWidgets';
import { Stream } from 'user/api/streams';
import SelectBox from '../../../../../shared/components/atoms/SelectBox';
import InputComponent from '../../../../../shared/components/molecules/InputComponent';
import HeatmapStreamSelect from '../../../../components/molecules/HeatmapStreamSelect';
import MetricsTable from '../components/MetricsTable';
import GraphSettingPanel, { GraphSettingPanelValues } from '../components/GraphSettingPanel';
import GraphMetricsTable from '../components/GraphMetricsTable';
import { MetricGraphType } from 'shared/models/MetricGraphType';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';

/**
 * ダッシュボードウィジェットの作成・編集画面で用いる、データ情報を表示させるパネル
 */
interface DashboardWidgetDataInformationProps {
  widget_type: string;
  player_streams: Stream[];
  metric_streams: Stream[];
  metrics?: Metric[];
  html?: string;
  html_script?: string;
  create_graph: boolean;
  graph_type?: MetricGraphType;
  jsonpath_group_id?: string;
  category?: string;
  player_stream_id?: string;
  od_stream_id?: string;
  od_age?: boolean;
  od_gender?: boolean;
  hm_stream_id?: string;
  gp_stream_id?: string;
  onPlayerStreamIdChange: (player_stream_id: string) => void;
  onOdStreamIdChange?: (od_stream_id: string) => void;
  onOdAgeChange?: (od_age: boolean) => void;
  onOdGenderChange?: (od_gender: boolean) => void;
  onHmStreamIdChange?: (hm_stream_id: string) => void;
  onMetricsChange: (metrics: Metric[]) => void;
  onGrahpSettingChange: (p: GraphSettingPanelValues) => void;
  onGrahpCreateChange: (create_graph: boolean) => void;
  onLiveTextSettingChange: (p: LiveTextSettingPaneValues) => void;
}
const DashboardWidgetDataInformation: React.FC<DashboardWidgetDataInformationProps> = (param) => {
  // 最後に返却するcomponent
  let component: JSX.Element | null = null;
  if (param.widget_type === 'VIDEOPLAYER') {
    component = (
      <InputComponent text='データ' required>
        <SelectBox
          onChange={(e) => param.onPlayerStreamIdChange(e.currentTarget.value)}
          value={param.player_stream_id || ''}
          datas={param.player_streams.map((stream) => {
            return { name: stream.stream_name, value: stream.stream_id };
          })}
          long
        />
      </InputComponent>
    );
  } else if (param.widget_type === 'GRAPH') {
    if (param.create_graph) {
      component = (
        <InputComponent text='グラフ情報' required>
          <GraphMetricsTable
            values={param.metrics || []}
            streams={param.metric_streams}
            graph_type={param.graph_type || ''}
            gp_stream_id={param.gp_stream_id || ''}
            isGraphType={true}
            onCreate={param.onGrahpCreateChange}
            onChange={(value: Metric[]) => param.onMetricsChange(value)}
            isLiveText={false}
          />
        </InputComponent>
      );
    } else {
      component = (
        <InputComponent text='グラフ情報設定' required>
          <GraphSettingPanel
            metricStreams={param.metric_streams}
            create_graph={param.create_graph}
            onCreate={param.onGrahpCreateChange}
            onSettingChange={param.onGrahpSettingChange}
            onMetricChange={(value: Metric[]) => param.onMetricsChange(value)}
            values={{
              jsonpath_group_id: param.jsonpath_group_id,
              gp_stream_id: param.gp_stream_id,
              graph_type: param.graph_type,
              category: param.category,
            }}
          />
        </InputComponent>
      );
    }
  } else if (param.widget_type === 'LIVETEXT') {
    component = (
      <>
        <LiveTextSettingPane
          values={{ html: param.html, htmlScript: param.html_script }}
          onChange={param.onLiveTextSettingChange}
        />
        <MetricsTable
          values={param.metrics || []}
          streams={param.metric_streams}
          is_graph_type={false}
          onChange={(value) => param.onMetricsChange(value)}
          is_live_text={true}
        />
      </>
    );
  } else if (param.widget_type === 'MAP') {
    component = <DescriptionText>現時点では選択できる情報はありません</DescriptionText>;
  } else if (param.widget_type === 'BUSOD1' || param.widget_type === 'BUSOD2') {
    component = (
      <>
        <InputComponent text='データ' required>
          <SelectBox
            onChange={(e) => (param.onOdStreamIdChange ? param.onOdStreamIdChange(e.currentTarget.value) : null)}
            value={param.od_stream_id || ''}
            datas={param.metric_streams.map((stream) => {
              return { name: stream.stream_name, value: stream.stream_id };
            })}
            long
          />
        </InputComponent>
        <InputComponent text='性別の選択'>
          <CheckBoxWithText
            checked={param.od_gender || false}
            text={'性別'}
            onClick={() => (param.onOdGenderChange ? param.onOdGenderChange(!param.od_gender) : null)}
          />
        </InputComponent>
        <InputComponent text='年代の選択'>
          <CheckBoxWithText
            checked={param.od_age || false}
            text={'年代'}
            onClick={() => (param.onOdAgeChange ? param.onOdAgeChange(!param.od_age) : null)}
          />
        </InputComponent>
      </>
    );
  } else if (param.widget_type === 'BUSOD3') {
    component = (
      <InputComponent text='データ' required>
        <SelectBox
          onChange={(e) => (param.onOdStreamIdChange ? param.onOdStreamIdChange(e.currentTarget.value) : null)}
          value={param.od_stream_id || ''}
          datas={param.metric_streams.map((stream) => {
            return { name: stream.stream_name, value: stream.stream_id };
          })}
          long
        />
      </InputComponent>
    );
  } else if (param.widget_type === 'HEATMAP') {
    component = (
      <InputComponent text='データ' required>
        <HeatmapStreamSelect
          value={param.hm_stream_id || ''}
          onChange={(value) => (param.onHmStreamIdChange ? param.onHmStreamIdChange(value) : null)}
        />
      </InputComponent>
    );
  }

  // render部分
  // componentがnullはgraph_typeが指定以外なので「データ情報」という文字すら出現させない
  if (component === null) {
    return <DescriptionText>ウィジェットタイプを選択してください</DescriptionText>;
  } else {
    // componentがnull以外ならgraph_typeは指定のものなので「データ情報」という文字も合わせて返却する
    return (
      <>
        <BoldText>データ情報</BoldText>
        {component}
      </>
    );
  }
};

// -- styled components --

const BoldText = styled.div`
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: ${styles.interval_narrow_margin};
`;

export default DashboardWidgetDataInformation;
