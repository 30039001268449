import process_1_svg from 'assets/process_1.svg';
import process_2_svg from 'assets/process_2.svg';
import process_3_svg from 'assets/process_3.svg';
import process_4_svg from 'assets/process_4.svg';
import { SelectData } from 'shared/components/atoms/SelectBox';

export const dayOfWeekStr = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
export const streaming_reception_billing_options = [
  { name: '14日 (無料)', value: '14' },
  { name: '31日 (無料)', value: '31' },
  { name: '93日 (無料)', value: '93' },
  { name: '186日 (30,000円/月)', value: '186' },
  { name: '366日 (50,000円/月)', value: '366' },
];
export const widget_types: SelectData[] = [
  {
    name: 'グラフ',
    value: 'GRAPH',
  },
  {
    name: 'ライブテキスト',
    value: 'LIVETEXT',
  },
  {
    name: 'ビデオプレーヤー',
    value: 'VIDEOPLAYER',
  },
  {
    name: 'マップ',
    value: 'MAP',
  },
  {
    name: 'バス停毎の乗車人数、降車人数',
    value: 'BUSOD1',
  },
  {
    name: '日別の乗車人数',
    value: 'BUSOD2',
  },
  {
    name: '乗車バス停と降車バス停のヒートマップ',
    value: 'BUSOD3',
  },
  {
    name: 'ヒートマップ',
    value: 'HEATMAP',
  },
];

export const normal_process_texts = ['人数カウント', '車数カウント', '密度検知', '不法投棄'];

export const process_images = [process_1_svg, process_2_svg, process_3_svg, process_4_svg];

export const sample_result_csv_header = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];

export const sample_result_csv = [
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
  {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
  },
];
